export const rum = () => {
  const oldscript = document.getElementById('RUM');
  const script = document.createElement('script');
  script.src = 'https://platform.cj.com/static/install-rum.js';
  script.async = true;
  script.id = 'RUM';
  if (oldscript === null) {
    document.head.appendChild(script);
  } else {
    document.head.replaceChild(script, oldscript);
  }
};

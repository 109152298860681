import React from 'react';
import ReactDOM from 'react-dom';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { Redirect, Router, Route, IndexRoute, useRouterHistory, applyRouterMiddleware } from 'react-router';
import { routerReducer, syncHistoryWithStore } from 'react-router-redux';
import createBrowserHistory from 'history/lib/createBrowserHistory';

import { useScroll } from 'react-router-scroll';
import { reducer as formReducer } from 'redux-form';

import visualStackReducer from '@cjdev-internal/visual-stack-redux/lib/actions';
import { initAuth, handleOAuthCallback } from '@platform/platform-auth';

import configureStore from 'configure-store';
import { loadState } from 'util/localstorage';
import { getConfig } from './configuration';

import documentsReducer from 'actions/documents';
import specsReducer from 'actions/specList';
import personalAccessTokensReducer from 'actions/personalAccessTokens';
import specReducer from 'actions/currentSpec';

import AuthenticationOverview from 'containers/pages/authentication/AuthenticationOverview';
import GraphiQLPage from 'containers/pages/graphiQL/GraphiQLPage';
import GraphQLDocument from 'containers/pages/graphQLDocument/GraphQLDocument';
import GraphQLVoyager from 'containers/pages/graphQLVoyager/GraphQLVoyager';
import MarkdownDocument from 'containers/pages/markdownDocument/MarkdownDocument';
import PersonalAccessTokensDashboard from 'containers/pages/personalAccessTokens/PersonalAccessTokensDashboard';
import Home from 'containers/pages/home/';
import Page from 'containers/Page';
import Error from 'containers/pages/Error';

import './styles/global.css';
import './styles/content.css';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
import { unregister } from './registerServiceWorker';
unregister();

const reducer = combineReducers({
  documents: documentsReducer,
  specs: specsReducer,
  spec: specReducer,
  personalAccessTokens: personalAccessTokensReducer,
  form: formReducer,
  routing: routerReducer,
  visualStack: visualStackReducer,
});

const initialState = loadState();
const browserHistory = useRouterHistory(createBrowserHistory)();
const store = configureStore({ history: browserHistory, reducer, initialState });
const syncedHistory = syncHistoryWithStore(browserHistory, store);

const oauthConfig = {
  clientId: getConfig('OAUTH_CLIENT_ID'),
  domain: 'signin.cj.com',
  redirectUri: window.location.origin + '/oauth-callback',
};

initAuth(oauthConfig).then(
ReactDOM.render(
  <Provider store={store}>
    <Router history={syncedHistory} render={applyRouterMiddleware(useScroll())}>
      <Route path="/" component={Home} />

      <Route path="/docs" component={Page}>
        <Redirect from="product-feeds" to="/docs/data-imports/product-feeds" />
        <Redirect from="technical-guides/product-feeds" to="/docs/data-imports/product-feeds" />
        <Route path=":section/:name" component={MarkdownDocument} />
      </Route>

      <Route path="/graphql" component={Page}>
        <Route path="reference/:name" component={GraphQLDocument} />
        <Route path="reference/private/:name" component={GraphQLDocument} />
        <Route path="voyager/:name" component={GraphQLVoyager} />
        <Route path="voyager/private/:name" component={GraphQLVoyager} />
        <Route path="graphiql/:name" component={GraphiQLPage} />
        <Route path="graphiql/private/:name" component={GraphiQLPage} />
      </Route>

      <Route path="/authentication" component={Page}>
        <Route path="overview" component={AuthenticationOverview} />
      </Route>

      <Route path="/account">
        <Route path="personal-access-tokens" component={Page} >
          <IndexRoute component={PersonalAccessTokensDashboard} />
        </Route>
      </Route>

      <Route path="/oauth-callback" onEnter={() => handleOAuthCallback()} />
      <Route path="/error" component={Error} />

      <Redirect from="*" to="/" />
    </Router>
  </Provider>,
  document.getElementById('application')
));

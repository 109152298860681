import { compose, identity } from 'ramda';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';

export default ({ history, reducer, initialState }) => {
  const createStoreWithMiddleware = compose(
    applyMiddleware(
      thunkMiddleware,
      promiseMiddleware,
      routerMiddleware(history)
    ),
    // enable devtools if present
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : identity
  )(createStore);

  return createStoreWithMiddleware(reducer, initialState);
};

import React from 'react';
import LockIcon from 'mdi-react/LockOutlineIcon';
import ArrowRight from 'mdi-react/ArrowRightIcon';
import Card from '@cjdev-internal/visual-stack/lib/components/Card/';
import { getBearerToken } from '@platform/platform-auth';

import './styles.css';

export const LoginDirective = ({ action }) => (
  <Card className="login-card" onClick={() => getBearerToken()}>
    <div className="login-card-stack-left">
      <LockIcon className="lock-icon" />
      <div className="login-text">
        <div className="login-notification">You must be logged in to {action}.</div>
        <div className="login-subtext">Click here to log in.</div>
      </div>
    </div>
    <ArrowRight className="arrow-icon" />
  </Card>
);

export default LoginDirective;

import { xml2js } from 'xml-js';
import { load } from 'js-yaml';
import { getConfig } from '../configuration';

const DOCS_BASE = getConfig('DOCS_BASE');

export const getDocument = async (path) => {
  const response = await fetch(`${DOCS_BASE}/${path}`);
  return response.ok ? await response.text() : '';
};

export const getDocuments = async () => {
  const response = await fetch(`${DOCS_BASE}/index.yaml`);
  const responseText = await response.text();
  try {
    return load(responseText);
  } catch (e) {
    // something went wrong!
  }
};

export const listBucket = async () => {
  const response = await fetch(DOCS_BASE);
  const xml = await response.text();
  const { ListBucketResult: { Contents } } = xml2js(xml, { compact: true });
  return Array.isArray(Contents) ? Contents : [Contents];
};

import React from 'react';

import { ApplicationLayout } from '@cjdev-internal/visual-stack-redux/lib/layouts/ApplicationLayout';
import { ModalMountPoint } from '@cjdev-internal/visual-stack-redux/lib/components/Modal';

import DeveloperPortalSideNav from 'containers/SiteNavigationBar';

export default ({ children }) =>
  <ApplicationLayout
      sideNav={<DeveloperPortalSideNav />}>
    <ModalMountPoint />
    {children}
  </ApplicationLayout>;

import React from 'react';
import * as Scroll from 'react-scroll';

import { SlidingPanel } from '@cjdev-internal/visual-stack-redux/lib/components/SlidingPanel';

export class TableOfContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
    };
  }

  updateTab = (tab) =>
    this.setState({
      activeTab: tab,
    });

  render() {
    const { content } = this.props;
    const activeTabClass = (id) => (id === this.state.activeTab) ? ' active' : '';
    const tocLink = (header, i, classType) =>
      <div className={`toc ${classType}${activeTabClass(header.id)}`} key={`tableOfContents-${i}`}>
        <Scroll.Link to={`${header.id}`}
                     smooth={true}
                     containerId="container"
                     offset={-56}
                     onClick={() => this.updateTab(header.id)}
        >
          <Label name={header.value} isDeprecated={header.isDeprecated} />
        </Scroll.Link>
      </div>;

    return (
      <SlidingPanel initialActive={true}>
        { content.map((item, i) =>
            (item.depth <= 2)
              ? tocLink(item, i, 'top-level-item')
              : tocLink(item, i, 'second-level-item')
        )}
      </SlidingPanel>
    );
  }
}

const Label = ({ name, isDeprecated }) => (
  isDeprecated
    ? <span className="deprecated-label">{name}</span>
    : <span>{name}</span>
);

import * as R from 'ramda';
import { createAction, handleActions } from 'redux-actions';
import * as API from 'api/documents';

const SET_DOCUMENTS = 'developer-portal/docs/SET_DOCUMENTS';
export const setDocuments = createAction(SET_DOCUMENTS);

const FETCH_DOCUMENT = 'developer-portal/docs/FETCH_DOCUMENT';
export const fetchDocument = createAction(FETCH_DOCUMENT);

const SET_DOCUMENT = 'developer-portal/docs/SET_DOCUMENT';
export const setDocument = createAction(SET_DOCUMENT);

export const loadDocuments = () => async (dispatch) => {
  const documents = await API.getDocuments();
  dispatch(setDocuments(documents));
};

export const loadDocument = (uriPath, s3FilePath) => async (dispatch) => {
  const contents = await API.getDocument(s3FilePath);
  dispatch(setDocument([uriPath, contents]));
};

// FILE: { fileName: STRING, navName: STRING, segment: STRING, contents: ([FILE] || STRING || UNDEFINED) }
//   - a recursive object representing a file structure with a single root node,
//       where each FILE has several representations: its file name in storage,
//       the navigation name that will appear in site navigation menus,
//       and the url segment that will appear in the url bar. Each file also
//       contains contents, which can be either [FILES] (meaning this
//       file represents a folder), a STRING (meaning it's a document), or
//       UNDEFINED (meaning this represents a document that hasn't fetched yet).
// overFileAtPath :: ([FILE], [STRING], FILE -> FILE) -> [FILE]
// given:
//   - a list of FILEs, described above
//   - a list of strings representing the lens to a specific FILE in the first arg
//   - a transform function that takes a FILE and returns a FILE
// then: follow the path to the correct FILE, run the transform function on that FILE,
//   and return the entire structure with the new FILE in place of the transformed one.
const overFileAtPath = (files, path, transform) => {
  const [segment, ...otherSegments] = path;

  // short circuit if path too short
  if (R.isEmpty(path)) {
    return files;
  }

  return R.map(file => {
    // short circuit if file isn't the one with the segment we're looking for
    if (!(file.segment === segment)) {
      return file;
    }

    if (Array.isArray(file.contents)) {
      // normal case -- file is a folder, so recurse into it.
      file.contents = overFileAtPath(file.contents, otherSegments, transform);

      if (file.private_contents && Array.isArray(file.private_contents)) {
        file.private_contents = overFileAtPath(file.private_contents, otherSegments, transform);
      }
      return file;
    } else {
      // short circuit if path too long
      if (path.length > 1) {
        return file;
      }

      return transform(file);
    }
  }, files);
};

export default handleActions({
  [SET_DOCUMENTS]: (_, { payload }) => {
    // example: 'Simple Integration.md' -> 'Simple Integration'
    const fileNameToNavName = fileName => (
      fileName.trim().replace(/\..*/g, '')
    );

    // example: 'Simple Integration.md' -> 'simple-integration'
    const fileNameToSegment = fileName => (
      fileNameToNavName(fileName).toLowerCase().replace(/ /g, '-')
    );

    // FILE: { fileName: STRING, navName: STRING, segment: STRING, contents: [FILE || UNDEFINED] }
    // indexToDocuments: { section: STRING, contents: [STRING] } -> FILE
    const indexToDocuments = index => {
      const mapFiles = (contentsList) => R.map(fileName => ({
        fileName,
        navName: fileNameToNavName(fileName),
        segment: fileNameToSegment(fileName),
      }), contentsList);

      return R.map(({ section, contents, private_contents }) => ({
        fileName: section,
        navName: fileNameToNavName(section),
        segment: fileNameToSegment(section),
        contents: mapFiles(contents),
        private_contents: private_contents ? mapFiles(private_contents): [],
      }), index);
    };

    return indexToDocuments(payload);
  },

  [FETCH_DOCUMENT]: (oldState, { payload: { uriPath } }) => {
    const state = R.clone(oldState);
    const segments = uriPath.split('/');
    const transform = file => R.mergeRight(file, { isFetching: true });

    return overFileAtPath(state, segments, transform);
  },

  [SET_DOCUMENT]: (oldState, { payload }) => {
    const state = R.clone(oldState);
    const [path, newContents] = payload;
    const segments = path.split('/');
    const transform = file => R.mergeRight(file, { contents: newContents, isFetching: false });

    return overFileAtPath(state, segments, transform);
  },
}, []);

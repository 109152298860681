import { xml2js } from 'xml-js';
import { getConfig } from '../configuration';

const ASSET_BASE = getConfig('ASSET_BASE');

export const getSpec = async (path) => {
  const response = await fetch(`${ASSET_BASE}/${path}`);
  return response.ok ? await response.json() : {};
};

export const listBucket = async () => {
  const response = await fetch(ASSET_BASE);
  const xml = await response.text();
  const { ListBucketResult: { Contents } } = xml2js(xml, { compact: true });
  return Array.isArray(Contents) ? Contents : [Contents];
};

export const getSpecList = async () => {
  const matchAndFilter = (filePaths, regexp) => filePaths
    .map(path => path.match(regexp))
    .filter(a => a !== null)
    .map(match => ({ path: match[0], name: match[1] }));

  const bucketContents = await listBucket();
  const graphqlRegexp = new RegExp('^docs/api/graphql/public/(.+).json$');

  const filePaths = bucketContents.map(({ Key: { _text: fullPath } }) => fullPath);

  return matchAndFilter(filePaths, graphqlRegexp);
};

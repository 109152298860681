import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { isEmpty } from 'ramda';
import { Voyager } from 'graphql-voyager';
import 'graphql-voyager/dist/voyager.css';

import { objectView } from 'util/store';
import * as Project from 'projection.js';
import { loadSpec } from 'actions/currentSpec';
import { ErrorBoundary } from 'components/ErrorBoundary';
import './styles.css';
import { Rum } from '../../../components/Rum';

class GraphQLVoyager extends React.Component {
  componentDidUpdate() {
    const spec = this.props.spec;
    const name = this.props.routeParams.name;

    // this happens when we navigate directly from one voyager to another
    if (spec.name !== name) {
      this.loadMySpec();
    }

    if (isEmpty(spec)) {
      this.props.redirect('/');
    }
  }

  loadMySpec() {
    const { loadSpec } = this.props;
    const name = this.props.routeParams.name;
    if (this.props.route.path.includes('/private/'))
      loadSpec(`docs/api/graphql/private/${name}.json`);
    else {
      loadSpec(`docs/api/graphql/public/${name}.json`);
    }
  }

  componentDidMount() {
    this.loadMySpec();
  }

  render() {
    const spec = this.props.spec;
    const displayOptions = spec?.defaultRoot ? { rootType: spec.defaultRoot } : {};
    return (spec?.schema
      ? <ErrorBoundary errorTitleText="GraphQL Voyager could not be loaded for this API.">
          <Rum />
          <div className="graphQLVoyagerWrapper">
            <Voyager
              displayOptions={displayOptions}
              introspection={spec.schema}
              workerURI={'https://cdn.jsdelivr.net/npm/graphql-voyager@1.0.0-rc.30/dist/voyager.worker.min.js'}
            />
          </div>
        </ErrorBoundary>
      : <div />
    );
  }
}

export default connect(
  objectView({
    spec: Project.spec,
  }),
  { loadSpec, redirect: push }
)(GraphQLVoyager);

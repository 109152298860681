import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { isEmpty } from 'ramda';
import 'graphiql/graphiql.css';

import PageContent from '@cjdev-internal/visual-stack/lib/components/PageContent';
import { PageHeader, PageTitle } from '@cjdev-internal/visual-stack/lib/components/PageHeader';
import { isLoggedIn } from '@platform/platform-auth';
import { objectView } from 'util/store';
import * as Project from 'projection.js';
import { loadSpec } from 'actions/currentSpec';
import { ErrorBoundary } from 'components/ErrorBoundary/';
import { ErrorPage } from 'components/ErrorPage';
import LoginDirective from 'components/LoginCard';
import SiteFooter from 'components/SiteFooter';
import './styles.css';
import { GraphiQLComponent } from '../../../components/GraphiQLComponent';
import { Rum } from '../../../components/Rum';

class GraphiQLPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: undefined,
    };
  }

  async componentDidUpdate() {
    const isUserLoggedIn = await isLoggedIn();
    if (isUserLoggedIn !== this.state.isLoggedIn) {
      this.setState(() => ({ isLoggedIn: isUserLoggedIn }));
    }

    const spec = this.props.spec;
    const name = this.props.routeParams.name;

    // this happens when user navigates from one graphQL page to another
    if (spec === undefined || name !== spec.name) {
      await this.loadMySpec();
    }

    if (isEmpty(spec)) {
      this.props.redirect('/');
    }
  }

  loadMySpec() {
    const { loadSpec } = this.props;
    const name = this.props.routeParams.name;
    if (this.props.route.path.includes('/private/'))
      loadSpec(`docs/api/graphql/private/${name}.json`);
    else {
      loadSpec(`docs/api/graphql/public/${name}.json`);
    }
  }

  componentDidMount() {
    this.loadMySpec();
  }

  render() {
    const {
      spec,
    } = this.props;
    try {
      const url = spec?.url;
      if (this.state.isLoggedIn === false)
        return (
          <>
            <Rum />
            <PageHeader><PageTitle>{`${this.props.routeParams.name} GraphiQL`}</PageTitle></PageHeader>
            <PageContent>
              <LoginDirective action="use GraphiQL" />
            </PageContent>
            <SiteFooter />
          </>
        );
      else if (this.state.isLoggedIn === undefined || !spec)
        return <></>;
      else
        return (
          <ErrorBoundary errorTitleText="There was an error displaying GraphiQL.">
            <Rum />
            <PageHeader><PageTitle>{`${this.props.routeParams.name} GraphiQL - ${url}`}</PageTitle></PageHeader>
            <GraphiQLComponent spec={spec} />
          </ErrorBoundary>
        );
    } catch (err) {
      console.log(err);
      return <ErrorPage titleText="There was an error displaying GraphiQL." />;
    }
  }
}

export default connect(
  objectView({
    spec: Project.spec,
  }),
  { loadSpec, redirect: push }
)(GraphiQLPage);

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as R from 'ramda';
import * as Scroll from 'react-scroll';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';

import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack/lib/components/Panel';
import PageContent from '@cjdev-internal/visual-stack/lib/components/PageContent';
import { PageHeader, PageTitle } from '@cjdev-internal/visual-stack/lib/components/PageHeader';
import {
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
} from '@cjdev-internal/visual-stack/lib/components/Table';

import { objectView } from 'util/store';
import * as Project from 'projection.js';
import { loadSpec } from 'actions/currentSpec';
import { MarkdownContent, generateContentHeaders } from 'components/Markdown';
import { ErrorBoundary } from 'components/ErrorBoundary';
import SiteFooter from 'components/SiteFooter';
import { TableOfContents } from 'components/TableOfContents';
import './styles.css';
import { parseSchema } from '../../../graphql';
import { Rum } from '../../../components/Rum';

const generateSchemaHeaders = (schema) => {
  if (!schema)
    return [];

  const {
    operations: {
      queries,
      mutations,
      subscriptions,
    }, types: {
      objects,
      inputObjects,
      interfaces,
      unions,
      enums,
      scalars,
    },
  } = schema;

  const generateOperationHeaders = (operations, operationTypeId, operationTypeDisplayName) => {
    if (operations.length === 0)
      return [];
    const header = [{ id: operationTypeId, value: operationTypeDisplayName, depth: 2 }];
    const subHeaders = operations.map(
      item => ({ id: item.name, value: item.name, depth: 3, isDeprecated: item.isDeprecated })
    );
    return Array.prototype.concat(header, subHeaders);
  };

  const generateTypeHeaders = (types, kindId, kindDisplayName) => {
    if (types.length === 0)
      return [];

    const header = [{ id: kindId, value: kindDisplayName, depth: 2 }];
    const subHeaders = types.map(item => ({ id: item.name, value: item.name, depth: 3 }));
    return Array.prototype.concat(header, subHeaders);
  };

  const queryHeaders = generateOperationHeaders(queries, 'queries', 'Queries');
  const mutationHeaders = generateOperationHeaders(mutations, 'mutations', 'Mutations');
  const subscriptionHeaders = generateOperationHeaders(subscriptions, 'subscriptions', 'Subscriptions');

  const objectHeaders = generateTypeHeaders(objects, 'objects', 'Objects');
  const inputObjectHeaders = generateTypeHeaders(inputObjects, 'input-objects', 'Input Objects');
  const interfaceHeaders = generateTypeHeaders(interfaces, 'interfaces', 'Interfaces');
  const unionTypes = generateTypeHeaders(unions, 'unions', 'Unions');
  const enumHeaders = generateTypeHeaders(enums, 'enums', 'Enums');
  const scalarHeaders = generateTypeHeaders(scalars, 'scalars', 'Scalars');

  return Array.prototype.concat(
    queryHeaders,
    mutationHeaders,
    subscriptionHeaders,
    objectHeaders,
    inputObjectHeaders,
    interfaceHeaders,
    unionTypes,
    enumHeaders,
    scalarHeaders
  );
};

const generateTOCContents = (title, description, schema) => {
  const titleTab = [{ id: title, value: title, depth: 1 }];
  const descriptionTabs = generateContentHeaders(description);
  const schemaTabs = generateSchemaHeaders(schema);
  return Array.prototype.concat(
    titleTab,
    descriptionTabs,
    schemaTabs
  );
};

class GraphQLDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpecLoaded: false,
      schema: undefined,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const spec = props.spec;
    const name = props.routeParams.name;
    const isSpecLoaded = spec && spec.name === name;
    return { isSpecLoaded };
  }

  componentDidUpdate() {
    const spec = this.props.spec;
    const isSpecLoaded = this.state.isSpecLoaded;


    // this happens when user navigates directly from one api doc to another
    if (!isSpecLoaded) {
      this.loadMySpec();
    } else if (R.isEmpty(spec)) {
      this.props.redirect('/');
    }

    const target = window.location.hash;
    if (target !== undefined && target !== '') {
      const id = target.substring(1);
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
      }
    }
  }

  loadMySpec() {
    const { loadSpec } = this.props;
    const name = this.props.routeParams.name;
    if (this.props.route.path.includes('/private/'))
      loadSpec(`docs/api/graphql/private/${name}.json`);
    else {
      loadSpec(`docs/api/graphql/public/${name}.json`);
    }
  }

  componentDidMount() {
    this.loadMySpec();
  }

  render() {
    const spec = this.state.isSpecLoaded && this.props.spec;
    const schema = this.state.isSpecLoaded && parseSchema(spec.schema?.data?.__schema);

    return (
      <>
        <Rum />
        <PageHeader><PageTitle>{`${spec.name || ''} API Reference`}</PageTitle></PageHeader>
        <div className="graph-ql-container">
          <Scroll.Element id="container" className="scroll-container">
            <PageContent>
              <DocPanel spec={spec} schema={schema} />
              <SiteFooter />
            </PageContent>
          </Scroll.Element>
          <TableOfContents content={
            generateTOCContents(
              spec.name,
              spec.description,
              schema
            )
          } />
        </div>
      </>
    );
  }
}

export const DocPanel = ({ spec, schema }) =>
  <ErrorBoundary errorTitleText="We could not display this API reference documentation.">
    <Panel>
      <PanelBody>
        <Scroll.Element name={spec.name}><h1>{spec.name} API Reference</h1></Scroll.Element>
        {spec.url && <p>URL: {spec.url}</p>}
        <Description item={spec} />
        <Schema schema={schema} />
      </PanelBody>
    </Panel>
  </ErrorBoundary>;

export const Schema = ({ schema }) => {
  if (!schema)
    return <div />;

  const {
    operations: {
      queries,
      mutations,
      subscriptions,
    }, types: {
      objects,
      inputObjects,
      interfaces,
      enums,
      scalars,
    },
  } = schema;

  return (
    <>
      <Queries queries={queries} />
      <Mutations mutations={mutations} />
      <Subscriptions subscriptions={subscriptions} />
      <Objects objects={objects} />
      <InputObjects inputObjects={inputObjects} />
      <Interfaces interfaces={interfaces} />
      <Enums enums={enums} />
      <Scalars scalars={scalars} />
    </>
  );
};

export const Queries = ({ queries }) =>
  !R.isEmpty(queries)
  ? <>
      <Scroll.Element name="queries">
        <h2 id="queries">Queries</h2>
      </Scroll.Element>
      <Description item={queries} />
      {queries.map((field, i) => <TypeOperation operation={field} key={i} />)}
    </>
  :<div />;

export const Mutations = ({ mutations }) =>
  !R.isEmpty(mutations)
  ? <>
      <Scroll.Element name="mutations">
        <h2 id="mutations">Mutations</h2>
      </Scroll.Element>
      <Description item={mutations} />
      {mutations.map((field, i) => <TypeOperation operation={field} key={i} />)}
    </>
  :<div />;

export const Subscriptions = ({ subscriptions }) =>
  !R.isEmpty(subscriptions)
  ? <>
      <Scroll.Element name="subscriptions">
        <h2 id="subscriptions">Subscriptions</h2>
      </Scroll.Element>
      <Description item={subscriptions} />
      {subscriptions.map((field, i) => <TypeOperation operation={field} key={i} />)}
    </>
  : <div />;

export const TypeOperation = ({ operation }) =>
  <>
    <Scroll.Element name={operation.name}>
      <h4 id={operation.name}><Label item={operation} /> : <Type type={operation.type} /></h4>
    </Scroll.Element>
    <DeprecationReason item={operation} />
    <Description item={operation} />
    <FieldTable tableHeader="Argument" fields={operation.args} />
  </>;

export const FieldTable = ({ tableHeader, fields }) => {
  if (R.isNil(fields) || R.isEmpty(fields)) {
    return <div />;
  } else if (R.any(field => !R.isNil(field.args) && !R.isEmpty(field.args))(fields)) {
    return (
      <Table>
        <THead>
          <Tr>
            <Th>{tableHeader}</Th>
            <Th>Type</Th>
            <Th>Description</Th>
            <Th>Arguments</Th>
          </Tr>
        </THead>
        <TBody>
          {fields.map((field, i) => <FieldRow field={field} key={i} />)}
        </TBody>
      </Table>
    );
  } else {
    return (
      <Table>
        <THead>
          <Tr>
            <Th>{tableHeader}</Th>
            <Th>Type</Th>
            <Th>Description</Th>
          </Tr>
        </THead>
        <TBody>
          {fields.map((field, i) => <FieldRow field={field} key={i} />)}
        </TBody>
      </Table>
    );
  }
};

export const FieldRow = ({ field }) =>
  <Tr>
    <Td><Label item={field} /></Td>
    <Td><Type type={field.type} /></Td>
    <Td>
      <DeprecationReason item={field} />
      <Description item={field} />
    </Td>
    <Td>
      <FieldTable tableHeader="Argument" fields={field.args} />
    </Td>
  </Tr>;

export const Objects = ({ objects }) => (
  !R.isEmpty(objects)
    ? <>
        <Scroll.Element name="objects">
          <h2 id="objects">Objects</h2>
        </Scroll.Element>
        {objects.map((object, i) =>
          <TypeObject object={object} key={i} />
        )}
      </>
    : <div />
);

export const TypeObject = ({ object }) =>
  <>
    <Scroll.Element name={object.name}>
      <h4 id={object.name}>{object.name}</h4>
    </Scroll.Element>
    <Description item={object} />
    <InterfacesTable interfaces={object.interfaces} />
    <FieldTable tableHeader="Field" fields={object.fields} />
  </>;

export const InterfacesTable = ({ interfaces }) =>
  interfaces && interfaces.length > 0
    ? <Table>
        <THead>
          <Tr>
            <Th>Implements</Th>
          </Tr>
        </THead>
        <TBody>
          {interfaces.map((interface_, i) => <InterfaceRow interface_={interface_} key={i} />)}
        </TBody>
      </Table>
  : <div />;

export const InterfaceRow = ({ interface_ }) =>
  <Tr>
    <Td><Type type={interface_} /></Td>
  </Tr>;

export const InputObjects = ({ inputObjects }) => (
  !R.isEmpty(inputObjects)
    ? <>
        <Scroll.Element name="input-objects">
          <h2 id="input-objects">Input Objects</h2>
        </Scroll.Element>
        {inputObjects.map((inputObject, i) =>
          <TypeInputObject inputObject={inputObject} key={i} />
        )}
      </>
    : <div />
);

export const TypeInputObject = ({ inputObject }) =>
  <>
    <Scroll.Element name={inputObject.name}>
      <h4 id={inputObject.name}>{inputObject.name}</h4>
    </Scroll.Element>
    <Description item={inputObject} />
    <FieldTable tableHeader="Field" fields={inputObject.inputFields} />
  </>;

export const Interfaces = ({ interfaces }) => (
  !R.isEmpty(interfaces)
    ? <>
        <Scroll.Element name="interfaces">
          <h2 id="interfaces">Interfaces</h2>
        </Scroll.Element>
        {interfaces.map((interface_, i) =>
          <TypeInterface interface_={interface_} key={i} />
        )}
      </>
    : <div />
);

export const TypeInterface = ({ interface_ }) =>
  <>
    <Scroll.Element name={interface_.name}>
      <h4 id={interface_.name}>{interface_.name}</h4>
    </Scroll.Element>
    <Description item={interface_} />
    <ImplementationsTable implementations={interface_.possibleTypes} />
    <FieldTable tableHeader="Field" fields={interface_.fields} />
  </>;

export const ImplementationsTable = ({ implementations }) =>
  <Table>
    <THead>
      <Tr>
        <Th>Implementations</Th>
      </Tr>
    </THead>
    <TBody>
      {implementations.map((implementation, i) => <ImplementationRow implementation={implementation} key={i} />)}
    </TBody>
  </Table>;

export const ImplementationRow = ({ implementation }) =>
  <Tr>
    <Td><Type type={implementation} /></Td>
  </Tr>;

export const Enums = ({ enums }) => (
  !R.isEmpty(enums)
    ? <>
        <Scroll.Element name="enums">
          <h2 id="enums">Enums</h2>
        </Scroll.Element>
        {enums.map((enuum, i) =>
          <TypeEnum enuum={enuum} key={i} />
        )}
      </>
    : <div />
);

export const TypeEnum = ({ enuum }) =>
  <>
    <Scroll.Element name={enuum.name}>
      <h4 id={enuum.name}>{enuum.name}</h4>
    </Scroll.Element>
    <Description item={enuum} />
    <EnumValuesTable enumValues={enuum.enumValues} />
  </>;

export const EnumValuesTable = ({ enumValues }) =>
  <Table>
    <THead>
      <Tr>
        <Th>Value</Th>
        <Th>Description</Th>
      </Tr>
    </THead>
    <TBody>
      {enumValues.map((enumValue, i) => <EnumValueRow enumValue={enumValue} key={i} />)}
    </TBody>
  </Table>;

export const EnumValueRow = ({ enumValue }) =>
  <Tr>
    <Td><Label item={enumValue} /></Td>
    <Td>
      <DeprecationReason item={enumValue} />
      <Description item={enumValue} />
    </Td>
  </Tr>;

export const Scalars = ({ scalars }) => (
  !R.isEmpty(scalars)
    ? <>
        <Scroll.Element name="scalars">
          <h2 id="scalars">Scalars</h2>
        </Scroll.Element>
        <ScalarTable scalars={scalars} />
      </>
    : <div />
);

export const ScalarTable = ({ scalars }) =>
  <Table>
    <THead>
      <Tr>
        <Th>Name</Th>
        <Th>Description</Th>
      </Tr>
    </THead>
    <TBody>
      {scalars.map((scalar, i) => <ScalarRow scalar={scalar} key={i} />)}
    </TBody>
  </Table>;

export const ScalarRow = ({ scalar }) =>
  <Tr>
    <Td id={scalar.name}>{scalar.name}</Td>
    <Td><Description item={scalar} /></Td>
  </Tr>;

export const Type = ({
  type: {
    kind,
    name,
    ofType,
  },
}) => {
  if (kind === 'OBJECT' || kind === 'SCALAR' || kind === 'ENUM' ||
    kind === 'INTERFACE' || kind === 'INPUT_OBJECT') {
    return <a href={'#' + name}>{name}</a>;
  } else if (kind === 'NON_NULL') {
    return <span><Type type={ofType} />!</span>;
  } else if (kind === 'LIST') {
    return <span>[<Type type={ofType} />]</span>;
  } else {
    return <div />;
  }
};

export const Description = ({ item }) => (
  item.description
    ? <MarkdownContent markdown={item.description} />
    : <div />
);

export const Label = ({ item }) => (
  item.isDeprecated
    ? <span className="deprecated-label">{item.name}</span>
    : <span>{item.name}</span>
);

export const DeprecationReason = ({ item }) =>
  item.deprecationReason
    ? <div className="deprecation-reason-box">
        <AlertCircleIcon className="alert-circle-icon" />
        <MarkdownContent
          markdown={`<span class="deprecation-reason-label">Deprecated:</span> ${item.deprecationReason}`}
        />
      </div>
    : <div />;

export default connect(
  objectView({
    spec: Project.spec,
  }),
  { loadSpec, redirect: push }
)(GraphQLDocument);

import React from 'react';
import Card from '@cjdev-internal/visual-stack/lib/components/Card/';
import { AlertCircleOutlineIcon, ChevronRightIcon } from 'mdi-react';
import './styles.css';

export const ErrorPage = ({ titleText }) => {
  return (
    <div className="error-page">
      <Card className={'error-page-card'}>
        <span className="error-title"><AlertCircleOutlineIcon /> <span className="error-text">{titleText}</span></span>
        <div className="error-subtext">Please try again.</div>
        <a href="/" className="error-home-link"><span>Back to Home</span><ChevronRightIcon className="error-chevron-icon" /></a>
      </Card>
    </div>
  );
};

import React from 'react';
import { connect } from 'react-redux';

import { reset } from 'redux-form';
import LoginDirective from 'components/LoginCard';
import Card from '@cjdev-internal/visual-stack/lib/components/Card/';
import { Button } from '@cjdev-internal/visual-stack/lib/components/Button';
import * as L from '@cjdev-internal/visual-stack/lib/components/List';
import * as M from '@cjdev-internal/visual-stack/lib/components/Modal';
import PageContent from '@cjdev-internal/visual-stack/lib/components/PageContent';
import { PageHeader, PageTitle } from '@cjdev-internal/visual-stack/lib/components/PageHeader';
import { Panel } from '@cjdev-internal/visual-stack/lib/components/Panel';
import { isLoggedIn } from '@platform/platform-auth';
import CloseIcon from 'mdi-react/CloseIcon';
import { RegisterPersonalAccessTokenForm } from 'components/RegisterPersonalAccessTokenForm';
import { openModal, closeModal } from '@cjdev-internal/visual-stack-redux/lib';
import { identity } from 'ramda';

import { objectView } from 'util/store';

import * as Project from 'projection.js';

import { registerPersonalAccessToken, removePersonalAccessToken, reloadPersonalAccessTokens } from 'cascades/personalAccessTokens.js';
import SiteFooter from 'components/SiteFooter';

import './styles.css';
import { Rum } from '../../../components/Rum';

const PureConfirmationModal = ({ personalAccessTokenId, personalAccessTokenDescription, closeModal, removePersonalAccessToken }) =>
  <M.Modal>
    <M.Dialog>
      <M.Content>
        <M.Header title="Confirmation: Remove Personal Access Token" />
        <M.Body>Are you sure you want to remove: {personalAccessTokenDescription}?</M.Body>
        <M.Footer>
          <Button type="primary" onClick={() => closeModal()}>Cancel</Button>
          <Button type="danger" onClick={() => { removePersonalAccessToken(personalAccessTokenId); closeModal(); }}>Remove Personal Access Token</Button>
        </M.Footer>
      </M.Content>
    </M.Dialog>
  </M.Modal>;

const ConfirmationModal = connect(
  identity,
  { closeModal, removePersonalAccessToken }
)(PureConfirmationModal);

const TempTokenDisplay = ({ token, deleteToken }) =>
  <Card className="temp-personal-access-token-display">
    <div className="temp-personal-access-token-header">
      <h5>New Personal Access Token Created</h5>
      <button onClick={() => deleteToken()}>
        <CloseIcon />
      </button>
    </div>
    <div>
      <span className="temp-personal-access-token-name">{token.description}</span>:  <span className="temp-personal-access-token">{token.token}</span>
    </div>
    <div className="temp-personal-access-token-warning">Please store this token in a safe place for it will never be shown again.</div>
  </Card>;

const PersonalAccessTokensList = ({ personalAccessTokens, openConfirmationModal }) =>
  <Panel>
    <L.List>
      <L.Header labels={[
        { text: 'Description', width: 9 },
        { text: 'Actions', width: 3 },
      ]} />
      <L.Rows>
        {personalAccessTokens.map(personalAccessToken =>
          <L.Row
            key={personalAccessToken.id}>
            <L.Cell width={9}><div className="ellipsified" title={personalAccessToken.description}>{personalAccessToken.description}</div></L.Cell>
            <L.Cell width={3}>
              <Button type="info" onClick={() => openConfirmationModal(ConfirmationModal, { personalAccessTokenId: personalAccessToken.id, personalAccessTokenDescription: personalAccessToken.description })}>
                Remove Token
              </Button>
            </L.Cell>
          </L.Row>)
        }
      </L.Rows>
    </L.List>
  </Panel>;

class PersonalAccessTokensPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      tempTokenDisplayed: false,
      tempBody: {},
    };
  }

  async componentDidMount() {
    const isUserLoggedIn = await isLoggedIn();
    this.setState(() => ({ isLoggedIn: isUserLoggedIn }));
    if (isUserLoggedIn) {
      const { reloadPersonalAccessTokens } = this.props;
      reloadPersonalAccessTokens();
    }
  }

  startRegisterPersonalAccessToken2 = async (description) => {
    const body = await this.props.registerPersonalAccessToken(description);
    await this.props.reloadPersonalAccessTokens();
    this.setState(() => ({ tempTokenDisplayed: true, tempBody: body }));
    this.props.reset('personal access token register');
  }

  deleteToken = () =>
    this.setState(() => ({ tempTokenDisplayed: false, tempBody: {} }));

  render() {
    const { personalAccessTokens, openModal } = this.props;
    return (
      <div className="application-portal-wrapper">
        <Rum />
        <PageHeader><PageTitle>My Personal Access Tokens</PageTitle></PageHeader>
        <PageContent>
          <div className="application-intro-portal-text">
            <h2>Personal Access Tokens</h2>
            A Personal Access Token is a unique identification string for your account.
            Personal Access Tokens allow for secure authentication when accessing the CJ APIs.
            You should keep your personal access tokens secure and private. Never put them in publically accessible places, such as in client-side code or public code repositories.
            {/* You can find more information about how to create, manage and use Personal Access Tokens <a href="/docs">here.</a>*/}
            { !this.state.isLoggedIn
              ? <LoginDirective action="create personal access tokens" />
              : <div className="personal-access-token-register-group">
                  <RegisterPersonalAccessTokenForm
                    title="Register a New Personal Access Token"
                    onSubmit={({ description }) => this.startRegisterPersonalAccessToken2(description)} />
                  {this.state.tempTokenDisplayed &&
                    <TempTokenDisplay
                      token={this.state.tempBody}
                      deleteToken={() => this.deleteToken()} />}
                  {(personalAccessTokens.length > 0) &&
                  <div>
                    <h4>Manage Personal Access Tokens</h4>
                    <PersonalAccessTokensList
                      personalAccessTokens={personalAccessTokens}
                      openConfirmationModal={openModal} />
                  </div>
                  }
                </div>
            }
          </div>
          <SiteFooter />
        </PageContent>
      </div>);
  }
}

export default connect(
  objectView({
    personalAccessTokens: Project.personalAccessTokens,
    isFetching: Project.personalAccessTokensIsFetching,
  }),
  { openModal,
    registerPersonalAccessToken,
    removePersonalAccessToken,
    reloadPersonalAccessTokens,
    reset,
  }
)(PersonalAccessTokensPage);

import React from 'react';
import ReactDOM from 'react-dom';
import './CodeBlock.css';
import { highlightElement } from 'highlight.js';
import 'highlight.js/styles/nord.css';

export class CodeBlock extends React.Component {
  componentDidMount() {
    const code = ReactDOM.findDOMNode(this).firstChild;
    highlightElement(code);
  }

  render() {
    const { className, children } = this.props;
    return <pre className={className?`codeblock ${className}`:'codeblock'} {...this.props}>{children}</pre>;
  }
}

import { createAction, handleActions } from 'redux-actions';
import * as API from 'api/specs';

import { sortSchema } from '../graphql';

const SET_SPEC = 'developer-portal/spec/SET_SPEC';
export const setSpec = createAction(SET_SPEC);

export const loadSpec = path => async (dispatch) => {
  const spec = await API.getSpec(path);
  spec.schema = sortSchema(spec.schema);
  dispatch(setSpec(spec));
};

export default handleActions({
  [SET_SPEC]: (state, { payload }) => payload,
}, {});

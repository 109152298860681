import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RRLink } from 'react-router';

import {
  Link,
  LinkContentWrapper,
  LinkGroup,
  SideNav,
  UserMenu,
} from '@cjdev-internal/visual-stack-redux/lib/components/SideNav';
import CJLogo from '@cjdev-internal/visual-stack/lib/components/CJLogo';

import GraphqlIcon from 'mdi-react/GraphqlIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import LoginIcon from 'mdi-react/LoginIcon';
import KeyIcon from 'mdi-react/KeyIcon';
import { getBearerToken, isLoggedIn, logout } from '@platform/platform-auth';

import { loadDocuments } from 'actions/documents';
import { loadSpecList } from 'actions/specList';
import { objectView } from 'util/store';
import * as Project from 'projection';
import { rum } from '../util/rum';

const DevPortalUserMenu = () =>
  <UserMenu
    onLogout={async () => await logout(window.location.origin)}
    label={'Logged In'}
    firstInitial={'CJ'}
    color="#00AF66">
  </UserMenu>;

class DevPortalNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }

  async componentDidMount() {
    const { documents, specs, loadDocuments, loadSpecList } = this.props;
    if (R.isEmpty(documents)) {
      loadDocuments();
    }
    if (R.isEmpty(specs?.graphql)) {
      loadSpecList();
    }
    const isUserLoggedIn = await isLoggedIn();
    this.setState(() => ({ isLoggedIn: isUserLoggedIn }));
  }

  render() {
    const {
      documents,
      specs: { graphql },
    } = this.props;
    const userNav = this.state.isLoggedIn
      ? <DevPortalUserMenu />
      : <div className="vs-sidenav-user-menu">
          <Link>
            <a onClick={async () => await getBearerToken()}>
              <LinkContentWrapper icon={<LoginIcon />} label="Sign In" />
            </a>
          </Link>
        </div>;

    return (
      <SideNav
        userMenu={userNav}
        appName="DEVELOPER PORTAL"
        logo={<CJLogo />}
        logoBackground="#00AF66"
      >
        <Link>
          <RRLink to="/">
            <LinkContentWrapper icon={<HomeIcon />} label="Home" />
          </RRLink>
        </Link>

        <LinkGroup label="Authentication" svgIcon={<KeyIcon />}>
          <Link>
            <RRLink to="/authentication/overview">
              <LinkContentWrapper label="Overview" />
            </RRLink>
          </Link>
          <Link>
            <RRLink to="/account/personal-access-tokens">
              <LinkContentWrapper label="Personal Access Tokens" />
            </RRLink>
          </Link>
        </LinkGroup>

        {
          !R.isEmpty(graphql)
          ? <LinkGroup label="GraphQL API" svgIcon={<GraphqlIcon />}>
              {
                graphql.map((spec, i) =>
                  <div key={i}>
                    <Link>
                      <RRLink to={`/graphql/reference/${spec.name}`}>
                        <LinkContentWrapper label={`${spec.name} API Reference`} />
                      </RRLink>
                    </Link>
                    <Link>
                      <RRLink to={`/graphql/voyager/${spec.name}`}>
                        <LinkContentWrapper label={`${spec.name} GraphQL Voyager`} />
                      </RRLink>
                    </Link>
                    <Link>
                      <RRLink to={`/graphql/graphiql/${spec.name}`}>
                        <LinkContentWrapper label={`${spec.name} GraphiQL`} />
                      </RRLink>
                    </Link>
                  </div>)
              }
            </LinkGroup>
          : <div />
        }

        {
          R.map(({ navName: topLevelNavName, segment: topLevelSegment, contents: topLevelContents }) => (
            <LinkGroup label={topLevelNavName} svgIcon={<FileDocumentIcon />} key={topLevelSegment} >
              {
                R.map(({ navName, segment }) => (
                  <Link key={`${topLevelSegment}/${segment}`}>
                    <RRLink to={`/docs/${topLevelSegment}/${segment}`} onClick={() => rum()}>
                      <LinkContentWrapper label={navName} />
                    </RRLink>
                  </Link>
                ), topLevelContents)
              }
            </LinkGroup>
          ), documents)
        }

      </SideNav>
    );
  }
}

export default connect(
  objectView({
    documents: Project.documents,
    specs: Project.specs,
  }),
  {
    loadDocuments: loadDocuments,
    loadSpecList: loadSpecList,
  }
)(DevPortalNav);

import { getBearerToken } from '@platform/platform-auth';
import { getConfig } from '../configuration';

const PAT_SERVICE_BASE = getConfig('PAT_SERVICE_BASE');

export const getPersonalAccessTokens = async () => {
  const bearerToken = await getBearerToken();

  return fetch(`${PAT_SERVICE_BASE}/personalTokens`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const registerPersonalAccessToken = async (description) => {
  const bearerToken = await getBearerToken();

  return fetch(`${PAT_SERVICE_BASE}/personalToken`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ description }),
  });
};

export const removePersonalAccessToken = async (personalAccessTokenId) => {
  const bearerToken = await getBearerToken();

  return fetch(`${PAT_SERVICE_BASE}/personalToken/${personalAccessTokenId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

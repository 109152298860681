import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { Form, FormGroup } from '@cjdev-internal/visual-stack/lib/components/Form';
import { SubmitButton } from '@cjdev-internal/visual-stack/lib/components/Button';
import { TokenIcon } from '@cjdev-internal/visual-stack/lib/components/Icons';
import Card from '@cjdev-internal/visual-stack/lib/components/Card/';
import { renderInput, nameRequired } from 'components/FormValidation';

const RegisterPersonalAccessTokenFormPure = ({ title, handleSubmit }) =>
  <Card className="personal-access-token-card">
    <span><TokenIcon />{title}</span>
    <Form
      className="name-form-field-group"
      onSubmit={handleSubmit}>
      <FormGroup classes="personal-access-token-form-field">
        <Field
          displayName="Token Name"
          name="description"
          autoFocus
          type="text"
          component={renderInput}
          validate={nameRequired}
        />
      </FormGroup>
      <SubmitButton className="personal-access-token-button" type="solid-secondary">Register</SubmitButton>
    </Form>
  </Card>;

export const RegisterPersonalAccessTokenForm = reduxForm({
  form: 'personal access token register',
  fields: ['description'],
})(RegisterPersonalAccessTokenFormPure);

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as R from 'ramda';

import { objectView } from 'util/store';
import * as Project from 'projection.js';
import { loadDocument } from 'actions/documents';
import { Markdown } from 'components/Markdown';

const findBySegment = (segment, documents) => documents.find(R.propEq(segment, 'segment'));

class MarkdownDocument extends React.Component {
  componentDidUpdate() {
    const { documents, loadDocument, params } = this.props;
    if (!R.isEmpty(documents)) {
      const { section, name } = params;
      const guides = findBySegment(section, documents);
      const thisDoc = (guides && findBySegment(name, guides.contents)) || (guides && guides.private_contents && findBySegment(name, guides.private_contents));
      if (!thisDoc) {
        return this.props.redirect('/');
      }
      const s3FilePath = `${guides.fileName}/${thisDoc.fileName}`;
      return !thisDoc.contents && loadDocument(`${section}/${name}`, s3FilePath);
    }

    const target = window.location.hash;
    if (target !== undefined && target !== '') {
      const id = target.substring(1);
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
      }
    }
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  render() {
    const { documents, params } = this.props;
    const { section, name } = params;
    const guides = findBySegment(section, documents);
    const thisDoc = (guides && findBySegment(name, guides.contents)) || (guides && guides.private_contents && findBySegment(name, guides.private_contents));
    const markdown = thisDoc && thisDoc.contents;
    const docName = thisDoc && thisDoc.navName;
    return !R.isEmpty(markdown) ? (
      <Markdown name={docName} markdown={markdown} />
    ) : <div />;
  }
}

export default connect(
  objectView({
    documents: Project.documents,
  }),
  { loadDocument, redirect: push }
)(MarkdownDocument);

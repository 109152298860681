import React from 'react';
import * as Scroll from 'react-scroll';
import { mergeRight, union } from 'ramda';
import { defaultSchema } from 'hast-util-sanitize';
import raw from 'rehype-raw';
import rehype2react from 'rehype-react';
import sanitize from 'rehype-sanitize';
import slug from 'rehype-slug';
import outsideToc from 'remark-outer-toc';
import remarkParse from 'remark-parse';
import remark2rehype from 'remark-rehype';
import remarkSlug from 'remark-slug';
import unified from 'unified';


import PageContent from '@cjdev-internal/visual-stack/lib/components/PageContent';
import { PageHeader, PageTitle } from '@cjdev-internal/visual-stack/lib/components/PageHeader';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack/lib/components/Panel';

import { ErrorBoundary } from 'components/ErrorBoundary';
import SiteFooter from 'components/SiteFooter';
import { TableOfContents } from 'components/TableOfContents';

import {
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
} from '@cjdev-internal/visual-stack/lib/components/Table';

import { CodeBlock } from 'components/CodeBlock';

// TODO: use .process and return Promise
export const generateContentHeaders = markdown => {
  let result = {};
  const processor = unified()
    .use(remarkParse)
    .use(remarkSlug)
    .use(outsideToc, {
      callback: headers => {
        result = headers;
      },
      id: true,
      depth: 3,
    });
  const node = processor.parse(markdown);
  processor.runSync(node);
  return result;
};

export const Markdown = ({ name, markdown }) => (
  <>
    <PageHeader><PageTitle>{name}</PageTitle></PageHeader>
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 45px)' }}>
      <Scroll.Element id="container" className="scroll-container">
        <PageContent>
          <DocPanel markdown={markdown} />
          <SiteFooter />
        </PageContent>
      </Scroll.Element>
      <TableOfContents content={generateContentHeaders(markdown)} />
    </div>
  </>
);

export const DocPanel = ({ markdown = '' }) =>
  <ErrorBoundary errorTitleText="We could not display this documentation.">
    <Panel>
      <PanelBody>
        <div style={{ minHeight: 'calc(100vh - 201px)' }}>
          <MarkdownContent markdown={markdown} />
        </div>
      </PanelBody>
    </Panel>
  </ErrorBoundary>;

// `clobberPrefix: ''` removes 'user-content-' prefix from generated slugs
const sanitizeSchema = mergeRight(defaultSchema,
  {
    attributes: mergeRight(defaultSchema.attributes, { '*': union(defaultSchema.attributes['*'], ['className']) }),
    clobberPrefix: '',
    tagNames: union(defaultSchema.tagNames, ['span']),
  }
);

export const MarkdownContent = ({ markdown }) =>
  <>
    { unified()
      .use(remarkParse)
      .use(remark2rehype, { allowDangerousHtml: true })
      .use(slug)
      .use(raw)
      .use(sanitize, sanitizeSchema)
      .use(rehype2react, {
        createElement: React.createElement,
        components: {
          table: Table,
          thead: THead,
          tbody: TBody,
          tr: Tr,
          th: Th,
          td: Td,
          pre: (props) => <CodeBlock {...props} />,
        },
      })
      .processSync(markdown).contents
    }
  </>;

import * as Style from './styles.css';
import React from 'react';

export default () => (
  <div className={Style.footer}>
    © {new Date().getFullYear()} Commission Junction LLC |{' '}
    <a href="https://www.cj.com/legal/privacy">Privacy Policy</a> |{' '}
    <a href="https://www.cj.com/legal/terms ">Terms of Use</a> |{' '}
    <a href="https://www.cj.com/legal/ws-terms ">API Terms of Use</a>
  </div>
);

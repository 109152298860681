import { push } from 'react-router-redux';

import { isSuccess } from 'util/http';
import {
  fetchPersonalAccessTokens,
  setPersonalAccessTokens,
  removePersonalAccessTokenFromStore,
} from 'actions/personalAccessTokens';
import * as API from 'api/personalAccessTokens';

export const reloadPersonalAccessTokens = () => async (dispatch) => {
  dispatch(fetchPersonalAccessTokens());
  const response = await API.getPersonalAccessTokens();
  if (isSuccess(response.status)) {
    const body = await response.json();
    dispatch(setPersonalAccessTokens(body));
  } else if (response.status === 401) {
    dispatch(setPersonalAccessTokens());
  } else {
    dispatch(setPersonalAccessTokens());
    dispatch(push('/error'));
  }
};

export const registerPersonalAccessToken = description => async (dispatch) => {
  const response = await API.registerPersonalAccessToken(description);
  if (isSuccess(response.status)) {
    return await response.json();
  } else {
    dispatch(push('/error'));
  }
};

export const removePersonalAccessToken = personalAccessTokenId => async (dispatch) => {
  const response = await API.removePersonalAccessToken(personalAccessTokenId);
  if (isSuccess(response.status)) {
    dispatch(removePersonalAccessTokenFromStore(personalAccessTokenId));
  } else if (response.status === 401) {
  } else if (response.status === 404) {
    // failure: request header's bearer token does not include a user id that corresponds to a personal access token that is associated with the personal access token id in the URL
    dispatch(push('/error'));
  } else {
    // bad dog
    dispatch(push('/error'));
  }
};

import React from 'react';
import PageContent from '@cjdev-internal/visual-stack/lib/components/PageContent';
import { PageHeader, PageTitle } from '@cjdev-internal/visual-stack/lib/components/PageHeader';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack/lib/components/Panel';

import Page from 'containers/Page';
import SiteFooter from 'components/SiteFooter';

export default () =>
  <Page>
    <PageHeader><PageTitle>CJ Developer Portal</PageTitle></PageHeader>
    <PageContent>
      <Panel>
        <PanelBody>
          <div className="message-container">
            <span className="error">
              Oh no! Something has gone wrong.
            </span>
          </div>
        </PanelBody>
      </Panel>
    </PageContent>
    <SiteFooter />
  </Page>;

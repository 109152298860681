import React from 'react';
import PageContent from '@cjdev-internal/visual-stack/lib/components/PageContent';
import { PageHeader, PageTitle } from '@cjdev-internal/visual-stack/lib/components/PageHeader';
import Page from 'containers/Page';
import SiteFooter from 'components/SiteFooter';
import Card from '@cjdev-internal/visual-stack/lib/components/Card/';
import './styles.css';
import { Rum } from '../../../components/Rum';

export default () =>
  <Page>
    <Rum />
    <PageHeader><PageTitle>CJ Developer Portal</PageTitle></PageHeader>
    <PageContent>
      <div className="home-page">
        <h2>Welcome to the CJ Developer Portal!</h2>
        <p>Learn more about tracking integration, APIs, and data integration options.</p>
        <h4>General</h4>
        <div className="home-page-card-wrapper">
          <Card href="authentication/overview" className="home-page-card">
            <div className="home-page-card-title">
              <span>Authentication</span>
              <div>Learn about authenticating with CJ APIs and manage credentials.</div>
            </div>
          </Card>
          <Card href="graphql/reference/Commission%20Detail" className="home-page-card">
            <div className="home-page-card-title">
              <span>Commission Detail API</span>
              <div>The Commission Detail API has been upgraded to the GraphQL protocol and available data has been expanded.</div>
            </div>
          </Card>
        </div>
        <h4>For Advertisers</h4>
        <div className="home-page-card-wrapper">
          <Card href="/docs/advertiser-site-tracking/overview" className="home-page-card">
            <div className="home-page-card-title">
              <span>Getting Started With CJ Site Tracking</span>
              <div>These tracking integration guides will help you set up your affiliate tracking by placing tracking code within your site. Working with a CJ Technical Integration Engineer is required to complete your CJ integration.</div>
            </div>
          </Card>
          <Card href="/docs/advertiser-api-tracking/api-overview" className="home-page-card">
            <div className="home-page-card-title">
              <span>Getting Started With CJ API Tracking</span>
              <div>These tracking integration guides will help you set up your affiliate tracking by using non-browser based tracking. Working with a CJ Technical Integration Engineer is required to complete your CJ integration.</div>
            </div>
          </Card>
          <Card href="docs/data-imports/overview" className="home-page-card">
            <div className="home-page-card-title">
              <span>Data Imports</span>
              <div>Use data imports to send files to CJ via FTP or HTTP for Actions, Concluded Actions, Corrections, Extensions, Item Lists, and Product Feeds.</div>
            </div>
          </Card>
          <Card href="docs/data-imports/product-feeds" className="home-page-card">
            <div className="home-page-card-title">
              <span>Product Feeds For Shopping, Travel & Finance</span>
              <div>Send CJ a feed of your retail, travel or finance products to enable publishers to create unique experiences promoting your goods or services.</div>
            </div>
          </Card>
          <Card href="docs/rest-apis/publisher-lookup" className="home-page-card">
            <div className="home-page-card-title">
              <span>Publisher Lookup API</span>
              <div>Search partners with our Publisher Lookup REST API.</div>
            </div>
          </Card>
        </div>
        <h4>For Publishers</h4>
        <div className="home-page-card-wrapper">
          <Card href="graphql/reference/Product%20Feed" className="home-page-card">
            <div className="home-page-card-title">
              <span>Product Feed API</span>
              <div>GraphQL API to search for products to promote by criteria such as price, currency, country, serviceable area and UPC.</div>
            </div>
          </Card>
          <Card href="docs/rest-apis/advertiser-lookup" className="home-page-card">
            <div className="home-page-card-title">
              <span>Advertiser Lookup API</span>
              <div>REST API to find advertisers both joined and not joined with you, and find details about their programs.</div>
            </div>
          </Card>
          <Card href="docs/rest-apis/link-search" className="home-page-card">
            <div className="home-page-card-title">
              <span>Link Search API</span>
              <div>REST API to search for links by keywords, country, category, targeted area, relationship status (with the advertiser), or link type.</div>
            </div>
          </Card>
          <Card href="docs/rest-apis/automated-offer-feed" className="home-page-card">
            <div className="home-page-card-title">
              <span>Automated Offer Feed API</span>
              <div>REST API to access specific credit card content, links and images from our financial advertisers.</div>
            </div>
          </Card>
        </div>
        <h4>Have an API question or suggestion?</h4>
        <p>Visit <a href="https://www.cj.com/support" target="_blank">Support at CJ.com</a> for ways to contact us.</p>
        <h4>Interested in partnering with CJ?</h4>
        <p>If you’re an Advertiser or Publisher, sign up on our <a href="https://www.cj.com/join" target="_blank">Join page at CJ.com</a>.</p>
        <p>If you’re a Tech Partner, review our information and submit a lead form found on our <a href="https://www.cj.com/advertiser/tech-partners" target="_blank">Tech Partners page on CJ.com</a>.</p>
      </div>
      <SiteFooter />
    </PageContent>
  </Page>;

import { createAction, handleActions } from 'redux-actions';
import * as API from 'api/specs';

const SET_SPECS = 'developer-portal/specs/SET_SPECS';
export const setSpecs = createAction(SET_SPECS);

export const loadSpecList = () => async (dispatch) => {
  const specs = await API.getSpecList();
  dispatch(setSpecs(specs));
};

export default handleActions({
  [SET_SPECS]: (state, { payload }) => { return { graphql: payload }; },
}, {
  graphql: [],
});

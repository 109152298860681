import React from 'react';
import { Input } from '@cjdev-internal/visual-stack/lib/components/Form';

export const required = errorMessage => value => value !== undefined ? undefined : errorMessage;
export const nameRequired = required('Name required');

export const renderInput = ({ displayName, type, input, meta: { touched, error } }) =>
  <>
    <label>{displayName}</label>
    {touched && (error && <span className="error">{error}</span>)}
    <Input className={touched && error ? ' error' : ''} type={type} {...input} />
  </>;

import React from 'react';
import { ErrorPage } from 'components/ErrorPage';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? <ErrorPage titleText={this.props.errorTitleText} /> : <>{ this.props.children }</>;
  }
}

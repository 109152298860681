import { mergeRight, filter } from 'ramda';
import { createAction, handleActions } from 'redux-actions';

const FETCH_PERSONAL_ACCESS_TOKENS = 'developer-portal/personal-access-tokens/FETCH_PERSONAL_ACCESS_TOKENS';
export const fetchPersonalAccessTokens = createAction(FETCH_PERSONAL_ACCESS_TOKENS);

const SET_PERSONAL_ACCESS_TOKENS = 'developer-portal/personal-access-tokens/SET_PERSONAL_ACCESS_TOKENS';
export const setPersonalAccessTokens = createAction(SET_PERSONAL_ACCESS_TOKENS);

const REMOVE_PERSONAL_ACCESS_TOKEN = 'developer-portal/personal-access-tokens/REMOVE_PERSONAL_ACCESS_TOKEN';
export const removePersonalAccessTokenFromStore = createAction(REMOVE_PERSONAL_ACCESS_TOKEN);

export default handleActions({
  [FETCH_PERSONAL_ACCESS_TOKENS]: (state, _) =>
    mergeRight(state, { isFetching: true }),

  [SET_PERSONAL_ACCESS_TOKENS]: (state, { payload }) =>
    mergeRight(state, { tokens: payload || state.tokens, isFetching: false }),

  [REMOVE_PERSONAL_ACCESS_TOKEN]: (state, { payload }) =>
    mergeRight(state, { tokens: filter(personalAccessToken => personalAccessToken.id !== payload, state.tokens) }),

}, { tokens: [], isFetching: false });

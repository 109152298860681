import * as R from 'ramda';

export const parseSchema = (schema) => {
  const isHiddenField = fieldName => fieldName.startsWith('__');
  const getOperations = (operationType, types) =>
    operationType
      ? types.find(({ name }) => name === operationType).fields
      : [];

  if (!schema || !schema.types)
    return undefined;

  const queryType = schema.queryType?.name;
  const mutationType = schema.mutationType?.name;
  const subscriptionType = schema.subscriptionType?.name;

  const visibleTypes = schema.types.filter(type => !isHiddenField(type.name));

  const queries = getOperations(queryType, visibleTypes);
  const mutations = getOperations(mutationType, visibleTypes);
  const subscriptions = getOperations(subscriptionType, visibleTypes);

  const objects = visibleTypes.filter(({ kind, name }) =>
    kind === 'OBJECT' &&
    name !== queryType &&
    name !== mutationType &&
    name !== subscriptionType
  );
  const inputObjects = visibleTypes.filter(
    ({ kind }) => kind === 'INPUT_OBJECT'
  );
  const interfaces = visibleTypes.filter(
    ({ kind }) => kind === 'INTERFACE'
  );
  const unions = visibleTypes.filter(
    ({ kind }) => kind === 'UNION'
  );
  const enums = visibleTypes.filter(
    ({ kind }) => kind === 'ENUM'
  );
  const scalars = visibleTypes.filter(
    ({ kind }) =>  kind === 'SCALAR'
  );

  return {
    operations: {
      queries,
      mutations,
      subscriptions,
    },
    types: {
      objects,
      inputObjects,
      interfaces,
      unions,
      enums,
      scalars,
    },
  };
};

const sort = R.compose(
  R.sortBy(R.prop('name')),
  R.map(x => {
    if (x.fields && !R.isEmpty(x.fields)) {
      x.fields = sort(x.fields);
    }
    if (x.args && !R.isEmpty(x.args)) {
      x.args = sort(x.args);
    }
    return x;
  })
);

export const sortSchema = (schema) => {
  if (!schema?.data?.__schema?.types)
    return undefined;
  try {
    schema.data.__schema.types = sort(schema.data.__schema.types);
    return schema;
  } catch (err) {
    return undefined;
  }
};

import React from 'react';
import { rum } from '../../util/rum';

export class Rum extends React.Component {
  componentDidMount() {
    rum();
  }
  render() {
    return <div />;
  }
}

import React from 'react';

import GraphiQL from 'graphiql';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { buildClientSchema } from 'graphql';
import { getBearerToken } from '@platform/platform-auth';
import { StorageAPI } from '@graphiql/toolkit';

class GraphiqlStorage extends StorageAPI {
  constructor(namespace) {
    super();
    this.prefix = namespace;
    this.length = 0;
  }
  getItem(key) {
    const item = window.localStorage.getItem(`${this.prefix}:${key}`);
    if ((!item) && (key==='graphiql:queries' || key==='graphiql:query' || key==='graphiql:tabState'))
      return window.localStorage.getItem(`${key}`);
    else
      return item;
  }
  setItem(key, value) {
    window.localStorage.setItem(`${this.prefix}:${key}`, value);
    length++;
  }
  removeItem(key) {
    window.localStorage.removeItem(`${this.prefix}:${key}`);
    length--;
  }
  clear() {
    for (const key in window.localStorage) {
      if (key.indexOf(`${this.prefix}:graphiql`) === 0) {
        window.localStorage.removeItem(key);
      }
    }
    length=0;
    super.clear();
    window.location.reload();
  }
}

const graphQLFetcher = url => async (graphQLParams, opts)  => {
  const bearerToken = await getBearerToken();
  return createGraphiQLFetcher({
    headers: { Authorization: `Bearer ${bearerToken}` },
    url: url,
  })(graphQLParams, opts);
};

export const GraphiQLComponent = ({ spec }) => {
  if (!spec)
    return <></>;
  const schema = spec.schema?.data;
  const url = spec.url;
  const key = spec.name;
  const graphQLSchema = schema !== undefined ? buildClientSchema(schema) : null;
  return (
    <div className="graphiQLWrapper">
      <GraphiQL key={key}
        fetcher={graphQLFetcher(url)}
        schema={graphQLSchema}
        storage={new GraphiqlStorage(key)}
      />
    </div>
  );
};
